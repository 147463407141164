<template>
  <tab-container>
    <div v-if="liveData">
      <div slot="operation" :class="$style.updateText">
        <a-icon type="sync" :spin="refreshFlag" :class="$style.refreshIcon" />
        {{ $t('detail.lastUpdateTime') }}：{{
          formateTime(liveData.lastUpdateTime)
        }}
      </div>
      <div :class="$style.baseInfo">
        <div :class="$style.infoItem">
          <a-col
            :span="12"
            v-for="(item, key) in baseInfoText"
            :key="key"
            :class="$style.item"
          >
            <div :class="$style.info">
              <div :class="$style.label">
                {{ item }}
              </div>
              <div>{{ baseInfoList[key] || '--' }}</div>
            </div>
          </a-col>
        </div>
      </div>
    </div>
    <div v-if="!liveData" style="height: 85%">
      <EmptyContent />
    </div>
  </tab-container>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceLiveData } from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    TabContainer,
    EmptyContent,
  },
})
export default class LiveData extends Vue {
  @Prop({ type: String, default: undefined }) deviceId;
  mounted() {
    this.getData();
  }
  liveData = {};
  noData = false;
  async getData() {
    try {
      this.liveData = await deviceLiveData(
        this.deviceId,
        deviceTypeEnum.SP.deviceGroup,
      );
    } catch {
      this.liveData = null;
    }
  }
  get baseInfoList() {
    return {
      SwitchStatus:
        this.liveData.switchStatus === 1
          ? this.$t('greenConstruction.close')
          : this.$t('greenConstruction.open'),
      // Status: this.liveData.status === 1 ? '关' : '开',
    };
  }
  baseInfoText = {
    SwitchStatus: this.$t('greenConstruction.switchState'),
    // Status: '水箱状态',
  };
  refreshFlag = false;
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
}
</script>

<style lang="less" module>
.updateText {
  color: #999999;
  .refreshIcon {
    margin-right: 2px;
  }
}
.baseInfo {
  .infoItem {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .item {
      padding: 0 0 5px 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--border);
      .info {
        .label {
          color: var(--font-info);
          margin-bottom: 5px;
        }
      }
    }
  }
}
.refreshIcon {
  margin-right: 2px;
}
</style>
