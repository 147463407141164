<template>
  <div :class="$style.detail">
    <info-card :info="info" />
    <div :class="$style.contentBox">
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="live-data" :tab="$t('greenConstruction.liveData')">
          <live-data :deviceId="data.pkId" v-if="activeKey === 'live-data'" />
        </a-tab-pane>
        <!-- <a-tab-pane key="alarm-message">
          <span slot="tab">
            <a-badge
              :count="data.alarmCount"
              :number-style="{
                backgroundColor: 'var(--primary)',
                boxShadow: '0 0 0 1px var(--primary) inset',
                top: '-5px',
              }"
            >
              {{$t('greenConstruction.alarmMessage')}}
            </a-badge>
          </span>
          <alarm-message
            v-if="activeKey === 'alarm-message'"
            :deviceId="data.pkId"
          />
        </a-tab-pane> -->
        <a-tab-pane key="spray-log" :tab="$t('greenConstruction.sprayRecord')">
          <spray-log v-if="activeKey === 'spray-log'" :deviceId="data.pkId" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
// import AlarmMessage from './alarm-message.vue';
import LiveData from './live-data.vue';
import SprayLog from './spray-log.vue';
import InfoCard from '@/views/green-construction/components/info-card.vue';

@Component({
  components: {
    // AlarmMessage,
    LiveData,
    SprayLog,
    InfoCard,
  },
})
export default class FenceDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Function, default: () => {} }) close;
  get info() {
    return [
      {
        name: this.$t('safety.deviceName'),
        content: this.data.deviceName || '--',
      },
      {
        name: this.$t('safety.deviceCode'),
        content: this.data.deviceCode || '--',
      },
      {
        name: this.$t('safety.belongProject'),
        content: this.data.projectName || '--',
      },
      {
        name: this.$t('common.remark'),
        content: this.data.remark || '--',
      },
    ];
  }

  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }

  activeKey = 'live-data';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
}
</script>

<style lang="less" module>
.detail {
  padding-bottom: 0;
  padding-right: 0;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100vh - 75px);
  .text {
    color: var(--font-title);
  }
  .textHidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .contentBox {
    .boxTitle {
      font-size: 14px;
      color: var(--font);
      padding: 0 20px 10px;
    }
  }
}
</style>
