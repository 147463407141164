<template>
  <div :class="$style.content">
    <div>
      <table-search
        @handle-search-submit="handleSearchSubmit($event)"
        @handle-add-device="showFormModel({}, 'add')"
        :showStatusNum="false"
      />
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :row-key="record => record.deviceId"
        :loading="loading"
        :transformCellText="({ text }) => text || '-'"
        @change="handlePagination"
      >
        <template slot="action" slot-scope="record">
          <button-groups
            @handle-edit="showFormModel(record, 'edit')"
            @handle-detail="showDetail(record)"
            @handle-delete="deleteTips(record)"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { LayoutContent } from '@triascloud/x-blocks';
import FormModel from './spray-form.vue';
import DeviceDetail from './detail/index.vue';
import ButtonGroups from '@/views/green-construction/components/button-groups.vue';
import tableSearch from '@/views/green-construction/components/table-search.vue';
import { deviceTypeEnum } from '@/enum/device';
import TableCommon from '@/views/green-construction/components/mixins/table-common';

@Component({
  components: {
    LayoutContent,
    DeviceDetail,
    FormModel,
    ButtonGroups,
    tableSearch,
  },
})
export default class SprayControl extends mixins(TableCommon) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceName'),
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceCode'),
        dataIndex: 'deviceCode',
        ellipsis: true,
      },
      {
        title: this.$t('safety.belongProject'),
        dataIndex: 'projectName',
        ellipsis: true,
      },
      {
        title: this.$t('common.remark'),
        dataIndex: 'remark',
        ellipsis: true,
      },
      {
        title: this.$t('common.operation'),
        width: 200,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  showStatusNum = false;
  deviceGroup = deviceTypeEnum.SP.deviceGroup;
}
</script>

<style lang="less" module>
.content {
  padding: 20px;
}
:global(.DeviceDetail .ant-drawer-body) {
  padding: 0 !important;
}
:global {
  .DeviceDetail {
    .ant-drawer-header {
      .ant-drawer-close {
        left: 0;
      }
    }
    .feedBackBtn {
      position: fixed;
      top: 8px;
      right: 30px;
      z-index: 999;
      color: #ddd;
      font-size: 16px;
      cursor: pointer;
      .anticon {
        margin-right: 8px;
      }
    }
  }
}
</style>
